@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

html,
body {
    height: 100% !important;
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
}

#root {
    height: 100% !important;
}