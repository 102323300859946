@import '../../styles/colors.scss';
@import '../../styles/shadows.scss';

.DDM-select {
    font-size: 14px !important;
    height: 3.05vh !important;

    @media (max-width: 1000px) {
        font-size: 1vw !important;
        height: 5.42vh !important;
    }
}

.DDM-menuItem {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    color: $darkGrey;
    height: 4.20vh !important;

    @media (max-width: 1000px) {
        font-size: 0.75vw !important;
    }
}

.DDM-menuItemSelected {
    background-color: $lightBlue  !important;
}

.DDM-colorText {
    color: $primary;
}