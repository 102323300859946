@import "../../Styles/colors.scss";
@import "../../Styles/shadows.scss";

.AP-mainCon {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: $bgColor;
}

.AP-headerCon {
  width: 100vw !important;
  min-height: 12vh !important;
  background-color: $lightBlue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AP-getRecordCon {
  margin-right: 2vw !important;

  .AP-getRecordBtn {
    width: 9vw;
    height: 4.3vh !important;

    @media (max-width: 1000px) {
      height: 6vh !important;
    }

    border-color: $primary !important;
    color: $primary !important;
    border-radius: 5px !important;
    margin-left: 2vw !important;
    font-size: 0.7vw !important;
  }

  .MuiButton-outlined {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.AP-getRecordBtn:hover {
  color: white !important;
  border-color: $primary !important;
  background-color: $primary !important;
}

.AP-patientInfoCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-left: 2vw;
}

.AP-avatarImg {
  margin-right: 2vw;
  width: 3vw;
}

.AP-patientInfo {
  overflow-wrap: break-word;
  display: flex;
  flex-direction: row;
}

.AP-patientInfoTextColumn {
  margin-right: 3vw;
}

.AP-patientInfoText {
  color: $darkGrey;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  max-width: 45vw;
  font-size: 0.9vw;
}

.AP-graphsPanel {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.AP-dbTextCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4.1vh;
}

.AP-dbIcon {
  width: 0.9vw;
  margin-right: 0.6vw;
}

.AP-recordIdIcon {
  width: 1.3vw !important;
  margin-right: 0.4vw;
  margin-left: 3vw;
  color: $grey;
}

.AP-graphsImg {
  box-shadow: $mainBoxShadow;
  width: 90vw;
}

.AP-inputCon {
  margin-top: 1.5vh !important;
  margin-bottom: 2vh !important;
  height: 5vh !important;

  @media (max-width: 1000px) {
    margin-top: -2.2px;
  }
}

.AP-ddm {
  width: 15vw;
  margin-right: 1.8vw !important;
  font-size: 0.8vw !important;
}

.AP-ddmLong {
  width: 24vw;
  margin-right: 0.3vw !important;
  font-size: 0.7vw !important;
}

.AP-btnsCon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.AP-circularProgress {
  margin: 3vw;
}

.AP-circularProgressSubmit {
  margin-left: 5.65vw !important;
  margin-right: 5.65vw !important;
}

.AP-customAnoBtn {
  width: 12.5vw !important;
  height: 4.2vh !important;

  @media (max-width: 1000px) {
    height: 6vh !important;
  }

  border-color: $primary !important;
  color: $primary !important;
  margin-left: 1.6vw !important;
  font-size: 0.7vw !important;
}

.AP-customAnoBtn:hover {
  color: white !important;
  background-color: $primary !important;
}

.AP-submitAnoBtn {
  width: 11vw !important;
  height: 4.2vh !important;

  @media (max-width: 1000px) {
    height: 6vh !important;
  }

  border-color: $primary !important;
  background-color: $primary !important;
  font-size: 0.8vw !important;
}

.AP-submitAnoBtn:hover {
  border-color: $primaryBright !important;
  background-color: $primaryBright !important;
}

.AP-anoCon {
  height: 100%;
  display: flex;
  flex-direction: column;
  clear: both;
  margin-top: 0.8vh;
}

.AP-bottomCon {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $bgColor;
}

.AP-gridNotAvailableText {
  margin: 5vh;
}

.MuiFormLabel-root {
  margin-top: 0.6vh;
  margin-bottom: -0.6vh !important;
  font-size: 0.95vw !important;
}

.Mui-focused fieldset {
  color: $primary !important;
  border-width: 1px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary !important;
}

.MuiRadio-root {
  svg {
    width: 1.3vw !important;
    height: 1.3vw !important;
  }
}

.Mui-checked {
  color: $primary !important;
}

.MuiFormControlLabel-root {
  color: $darkGrey !important;
}

.AP-formControlLabel {
  font-size: 0.95vw !important;
}

.AP-ddmGridSize {
  width: 4.6vw !important;
  margin-left: -1px !important;

  @media (max-width: 1000px) {
    width: 8vw !important;
  }
}

.AP-ddmSelectGridSize {
  font-size: 0.9vw !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: $primary !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  height: 4.63vh !important;

  @media (max-width: 1000px) {
    height: 6.3vh !important;
  }
}

.AP-newAnnoBtn {
  width: 12vw !important;
  height: 4.2vh !important;
  border-color: $primary !important;
  color: $primary !important;
  font-size: 0.7vw !important;
  margin-bottom: 0.7vh !important;

  @media (max-width: 1000px) {
    height: 6vh !important;
  }
}

.AP-newAnnoBtn:hover {
  background-color: $bgColor !important;
}
