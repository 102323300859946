.Anno-con {
  flex-direction: row !important;
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center !important;
  height: 6.5vh !important;
}

.Anno-ddmPriority {
  margin-right: 1.8vw !important;
  font-size: 0.7vw !important;
  margin-bottom: 0.42vh !important;
  width: 3%;
  margin-bottom: 6px !important;
}

.Anno-CloseBtn {
  min-width: 2.3vw !important;
  min-height: 2.3vw !important;
  border-radius: 100px !important;
  margin-left: 1vw !important;
  margin-right: -3.3vw !important;
  color: rgb(237, 110, 110) !important;
}
