@import '../../Styles/colors.scss';

.LP-mainCon {
    background-color: white;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) and (orientation: landscape) {
        flex-direction: row;
        justify-content: space-evenly;
        padding-top: 8vh;
    }
}

.LP-lablerLogoImg {
    margin-top: 9vh;
    height: 9vw;

    @media (max-width: 1000px) {
        height: 9vh;

        @media (orientation: landscape) {
            height: 15vw;
            margin-top: -30vh;
        }
    }
}

.LP-loginPanel {
    width: 250px;
    height: 300px;
    margin-top: 15vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    @media (max-width: 1000px) {
        margin-top: 7vh !important;

        @media (orientation: landscape) {
            width: 330px;
            margin-top: 10vh !important;
        }
    }
}

.LP-inputField {
    width: 100%;
    margin-bottom: 4vh !important;
}

.LP-loginBtn {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 11vh !important;
    height: 40px;
    background-color: $primary  !important;
    border-radius: 5px !important;

    @media (max-width: 1000px) {
        margin-top: 4vh !important;

        @media (orientation: landscape) {
            margin-top: 4vh !important;
            margin-bottom: 10vw !important;
        }
    }
}

.LP-loginBtn:hover {
    background-color: $primaryBright  !important;
}

.LP-hatoLogoImgCon {
    position: fixed;
    bottom: 30px;
    z-index: 3;

    @media (max-width: 1000px) and (orientation: landscape) {
        bottom: 15px;
    }
}

.LP-hatoLogoImg {
    width: 20vh;
    height: 2.36vh;

    @media (max-width: 1000px) and (orientation: landscape) {
        width: 30vh;
        height: 3.54vh;
    }
}

.LP-bottomGradient {
    width: 100%;
    height: 30px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(221, 243, 245, 1) 47%, rgba(131, 200, 206, 1) 100%);
    position: fixed;
    bottom: 0px;

    @media (max-width: 1000px) and (orientation: landscape) {
        height: 20px;
    }
}